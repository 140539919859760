<template>
	<div class="box">
		<div class="row">
			<div class="col-12 px-0">
				<h2>Suivi du poids</h2>
				<router-link class="btn btn-outline-primary mr-2" :to="{ name: 'HorseCroissanceGraph', params: {horse_id: horse_id}}">{{ $t('croissance.comparer_population') }} <font-awesome-icon :icon="['fal', 'chart-pie']" /></router-link>
        		<router-link class="btn btn-primary" :to="{ name: 'AddHorseCroissance', params: {horse_id: horse_id}}">{{ $t('global.new_entrie') }} <font-awesome-icon :icon="['fal', 'plus']" /></router-link>
			</div>
			<div v-if="ready" class="col-12 mt-4">
				<table class="table table-hover table-sm mb-0">
					<thead>
						<tr>
							<th>{{ $t('croissance.date_mesure') }}</th>
							<th>{{ $t('croissance.horse_perimetre') }}</th>
							<th>{{ $t('croissance.horse_garrot') }}</th>
							<th>{{ $t('croissance.horse_masse') }}</th>
							<th>{{ $t('croissance.modification') }}</th>
						</tr>
					</thead>

					<tbody>
					<tr v-for="croissance in croissances" :key="croissance.croissance_id">
						<td>
							{{ croissance.date }}
						</td>
						<td>{{ croissance.croissance_perimetre }}</td>
						<td>{{ croissance.croissance_garrot }}</td>
						<td >{{ croissance.croissance_poids }}</td>
						<td ><router-link class="btn btn-primary" :to="{ name: 'AddHorseCroissance', params: {horse_id: horse_id, croissance_id: croissance.croissance_id }}">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link></td>
					</tr>
					</tbody>
				</table>
			</div>
			<LoadingSpinner v-else />
		</div>
	</div>
</template>

<script type="text/javascript">
	import Croissance from "@/mixins/Croissance.js"

	export default {
		name: 'HorseCroissance',
		props: ['horse_id'],
		mixins: [Croissance],
		data () {
			return {
				croissances: [],
				ready: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.ready = false
				this.croissances = await this.getCroissanceByHorse(this.horse_id)

				this.croissances.forEach(croissance => {
					croissance.date = Date.parseTz(croissance.croissance_date).toLocaleDateString()
				})
				this.ready = true
			}
		},

		watch: {
			'$route.name'() {
				this.croissances = []
				this.init_component()
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>